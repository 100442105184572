// Here you can add other styles
.item-summary p:not(:first-child) {
  margin-left: 2em;
}

.minH-30 {
  min-height: 30vh;
}

.minH-78 {
  min-height: 78vh;
}

.minH-75 {
  min-height: 65vh;
}
.minH-85 {
  min-height: 85vh;
}

.minH-100 {
  min-height: 100dvh;
}

.maxW-200 {
  max-width: 240px;
}

.maxW-14 {
  max-width: 14rem;
}

.cursor-pointer {
  cursor: pointer;
}

.border-right-1 {
  border-right: 1px solid $gray-100;
}

.border-bottom-1 {
  border-bottom: 1px solid $gray-100;
}

.border-top-1 {
  border-top: 1px solid $gray-100;
}

.border-1 {
  border: 1px solid $gray-100;
  border-radius: 4px;
}

.grid-search-box {
  border: 1px solid $gray-100 !important;
}

.grid-p {
  border-left: 1px solid $gray-100;
  border-right: 1px solid $gray-100;

  &:hover {
    font-weight: semibold;
  }
}

.grid-p-bg-grey {
  background-color: $gray-100;
}

.grid-p-bg-none {
  background-color: none;
}

.all-data-grid {
  min-height: 670px;
}

.empty-box {
  border: 1px solid $gray-100;
  min-height: 100px;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.font-weight-bold {
  font-weight: bold;
}

.platform-card {
  height: 120px;
  cursor: pointer;
}

.platform-card img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.days-checkbox-list {
  width: 100px;
}

.date-picker-timepickers .time-picker .form-select {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
}

.platform-image {
  width: 4rem;
  margin-right: 1.6rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-2 {
  margin-right: 0.5rem;
}

.form-multi-select .dropdown-menu.show {
  max-height: 320px;
  overflow: scroll;
}

.pull_barcode {
  margin-top: 12px;
}

.go-to-dashboard-container {
  margin-top: -30px;
}

.page-not-found-logo {
  width: 150px;
}

.page-not-found-logo-container {
  position: absolute;
  top: 30px;
  left: 30px;
}
