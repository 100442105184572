.pswp::-webkit-scrollbar {
  display: none; /* Hide scroll bar for Safari and Chrome */
}

.pswp {
  -ms-overflow-style: none; /* Hide scroll bar for Internet Explorer 10+ */
  scrollbar-width: none; /* Hide scroll bar for Firefox */
}

.pswp img {
  object-fit: cover;
}

.return_img_thumbnail {
  object-fit: cover;
  cursor: pointer;
  height: 7rem;
  width: 7rem;
}

.picking_img {  
  object-fit: contain;
  height: 5rem;
  width: 4.5rem;
}

.picking_done_img {
  object-fit: contain;
  height: 5rem;
  width: 5rem;
}

.packing_img {  
  object-fit: contain;
  height: 3rem;
  width: 3rem;
}

.item_img {  
  object-fit: contain;
  height: 3rem;
  width: 3rem;
}

.package_img {  
  object-fit: fill;
  height: 6rem;
  width: 6rem;
}
