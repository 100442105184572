.status-open {
  background-color: $open;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-delivered {
  background-color: $delivered;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-unscheduled {
  background-color: $unscheduled;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-refused {
  background-color: $refused;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-lated {
  background-color: $lated;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-cancelled {
  background-color: $cancelled;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-missed {
  background-color: $missed;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-receiving {
  background-color: $receiving;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-completed {
  background-color: $completed;
  width: 100%;
  text-align: center;
  color: $white;
  margin: 0;
  height: 100% !important;
}
.status-open-bgColor {
  background-color: $open;
}
.status-delivered-bgColor {
  background-color: $delivered;
}
.status-unscheduled-bgColor {
  background-color: $unscheduled;
}
.status-refused-bgColor {
  background-color: $refused;
}
.status-lated-bgColor {
  background-color: $lated;
}
.status-cancelled-bgColor {
  background-color: $cancelled;
}
.status-missed-bgColor {
  background-color: $missed;
}
.status-receiving-bgColor {
  background-color: $receiving;
}
.status-completed-bgColor {
  background-color: $completed;
}
.status-open-color {
  color: $open;
}
.status-delivered-color {
  color: $delivered;
}
.status-unscheduled-color {
  color: $unscheduled;
}
.status-refused-color {
  color: $refused;
}
.status-lated-color {
  color: $lated;
}
.status-cancelled-color {
  color: $cancelled;
}
.status-missed-color {
  color: $missed;
}
.status-receiving-color {
  color: $receiving;
}
.status-completed-color {
  color: $completed;
}
.table-responsive {
  min-height: 280px;
}
.table-responsive-10 {
  .table-responsive {
    min-height: 10px !important;
  }
}
