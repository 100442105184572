.bg-cyan {
  background: $cyan;
}
.bg-blue {
  background: $blue;
}
.bg-indigo {
  background: $indigo;
}
.bg-pink {
  background: $pink;
}
.bg-yellow {
  background: $yellow;
}
.bg-purple {
  background: $purple;
}
.bg-orange {
  background: $orange;
}
.bg-teal {
  background: $teal;
}

.badgeHover:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.06);
}
