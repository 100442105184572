.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.font-8 {
  font-size: 8px;
  color: #000;
}
.font-9 {
  font-size: 9px;
  color: #000;
}
.font-10 {
  font-size: 10px;
  color: #000;
}
.font-12 {
  font-size: 12px;
  color: #000;
}
.font-14 {
  font-size: 14px;
  color: #000;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}
.font-30 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 500;  
}
.line-height-9 {
  line-height: 0.9;
}
