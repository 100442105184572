.client-name-a {
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.barcode-card-a {
  width: 408.6px;
  height: 121px;
}
.barcode-cover-a {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 11.3px;
}
.barcode-wrapper-a {
  height: 121px;
  width: 121px;
}
.client-name-b {
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.barcode-card-b {
  width: 240px;
  height: 94.5px;
}
.client-name-c {
  font-size: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.barcode-card-c {
  width: 386px;
  height: 193px;
}
